<template>
    <div class="users">
        <PageNavBar title="新增用户" :goBack="goBackAndRemoveKeepAlive" />

        <van-form @submit="onSubmit">
            <van-cell-group inset>

                <van-field v-model="nickName" name="nickName" label="用户名" placeholder="用户名"
                    :rules="[{ required: true, message: '请填写用户名' }]" />

                <van-field v-model="phoneNumber" type="tel" name="phoneNumber" label="手机号" placeholder="手机号"
                    :rules="[{ required: true, message: '手机号' }]" />

                <van-field name="sex" label="性别">
                    <template #input>
                        <van-radio-group v-model="sex" direction="horizontal">
                            <van-radio name="0">男</van-radio>
                            <van-radio name="1">女</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <van-field name="roleId" label="角色">
                    <template #input>
                        <van-radio-group v-model="roleId" direction="horizontal" disabled>
                            <van-radio name="3">阅读者</van-radio>
                            <van-radio name="1">管理员</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <van-field name="status" label="状态">
                    <template #input>
                        <van-radio-group v-model="status" direction="horizontal">
                            <van-radio name="1">启用</van-radio>
                            <van-radio name="0">停用</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

            </van-cell-group>

            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>

    </div>
</template>

<script>
import PageNavBar from '../../components/PageNavBar'
import { keepAliveMixin } from '../../mixins'
import { addUser } from '../../api/user'
import { Dialog } from 'vant'

export default {
    name: 'AddUserPage',
    mixins: [keepAliveMixin],
    components: {
        PageNavBar,
    },
    props: {
    },
    setup() {
    },
    mounted() {
    },
    data() {
        return {
            avatar: "",
            userName: "",
            nickName: "",
            phoneNumber: "",
            roleId: '3',
            roleName: '阅读者',
            sex: '0',
            status: '1'
        }
    },
    created() {
    },
    methods: {
        async onSubmit(user) {
            user.userName = user.phoneNumber
            user.avatar = this.avatar
            user.roleName = this.roleName

            let result = await addUser(user)
            
            Dialog.alert({
                message: result.msg,
            }).then(() => {
                if (result.code == 200) {
                    this.goBackAndRemoveKeepAlive()
                }
            })
            
        }
    }
}
</script>

<style lang="stylus" scoped>
</style> 